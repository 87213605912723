import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/development.env';

@Injectable({
	providedIn: 'root'
})

export class UserDetailApi {

  	base_url:any

	constructor(private http: HttpClient) {

		this.base_url = environment.base_url
	}

	getUserDetails() {
		
		return this.http.get(this.base_url+'/myalltel/user/detail')
	}

	getUserList() {
		
		return this.http.get(this.base_url+'/myalltel/user/list')
	}

	getUserRole() {
		
		return this.http.get(this.base_url+'/myalltel/user/roles')
	}

	createUser(data) {
		
		return this.http.post(this.base_url+'/myalltel/user', data)
	}

	updateUser(data) {
		
		return this.http.post(this.base_url+'/myalltel/user/update', data)
	}

	removeUser(data) {
		
		return this.http.post(this.base_url+'/myalltel/user/delete', data)
	}

	sendInvitation(data) {
		
		return this.http.post(this.base_url+'/myalltel/user/sendinvitation', data)
	}


}
