import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class SessionService {

	constructor() { }

  remmember_array: any = []
  acoount_id: any


	isLoggedIn() {
    return (localStorage.getItem('token') !== null)
  }

  logout(){
     // localStorage.removeItem('token')
     localStorage.removeItem('account_id')
  }

  setToken(token){
    return localStorage.setItem('token', token)
  }

  getToken(){
    return localStorage.getItem('token')
  }

  rememberMe(data) {

    localStorage.setItem('email', data.email)
    localStorage.setItem('password', data.password)
    localStorage.setItem('remember', data.remember_me)
  }

  getRememberMeItem() {

    this.remmember_array = []
    this.remmember_array.push({
      "email": localStorage.getItem('email'),
      "password": localStorage.getItem('password')
    })

    return this.remmember_array
  }

  setAccountId(account_id) {

    localStorage.setItem('account_id', account_id)

  }

  getAccountId() {

    this.acoount_id = localStorage.getItem('account_id')

    return this.acoount_id
  }

  removeAccountID() {

    localStorage.removeItem('account_id')
    localStorage.removeItem('token')
  }
}