
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';

//services
import { ConfigInternalService } from './config-internal.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor {

  url:any
  auth_login:any

  constructor(private injector: Injector, private router: Router, private session_service: SessionService, public internal_config: ConfigInternalService) { }

  intercept(req, next) {

  	let tokenizedReq = req.clone({
  		setHeaders: {
  			Authorization: `Bearer ${this.session_service.getToken()}`,
        Account: `${this.session_service.getAccountId()}`
  		}
  	})


  	return next.handle(tokenizedReq).pipe(
      tap(
        (event: any) => {
          // do nothing out of the ordinary
        }, 
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // this.router.navigate(['/auth/login'])
              this.url = window.location.pathname
              this.auth_login = '/auth/login'

              if(this.url != this.auth_login) {
                this.router.navigate(['/auth/login'], {queryParams: {redirect_url : this.url}})
              }
            }
            if (err.status === 400) {
               return observableThrowError(err);
            }
            
          }
        }
      ))
    //return next.handle(tokenizedReq);
  }
}
