import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { VersionCheckService } from './services/version-check.service';

import { environment } from './../environments/development.env';

//custom
import { SessionService } from './services/session.service';
import { ConfigInternalService } from './services/config-internal.service';

//api
import { UserDetailApi } from './api/user-detail.api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {

    constructor(private router: Router,  private session_service: SessionService,  public config_internal: ConfigInternalService,  private user_detail: UserDetailApi, private version_check: VersionCheckService) {

        router.events.subscribe( (event: Event) => {

            if (event instanceof NavigationStart) {

                // if(this.router.url == "/") {
    
                //     this.router.navigate(['/auth/login'])
                // } 

                if(this.session_service.getToken() == null) {

                    
                } else {

                    // this.user_detail.getUserDetails().subscribe((data:any) => {
            
                    // },
            
                    // (error:any) => {
                             
                    //     if(this.router.url.search("/customer/") == -1) {
            
                    //         this.router.navigate(['/auth/login'])
                    //     }
            
                    // })
                }
                // }
                // Show loading indicator
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
            }
        });

    }

    ngOnInit(){
        // this.version_check.initVersionCheck(environment.base_url)
    }
}
