import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-template',
  templateUrl: './auth-template.component.html',
  styleUrls: ['./auth-template.component.css']
})
export class AuthTemplateComponent implements OnInit {

  	constructor() { }

  	ngOnInit() {

  // 		const stylesheet_url = 'http://allteltest.test/assets/customer-styles.css';
		// const head = document.getElementsByTagName('head')[0];
		// const stylesheet = document.createElement('link');
		// stylesheet.type = 'text/css';
		// stylesheet.rel = 'stylesheet';
		// stylesheet.href = stylesheet_url;
		// head.appendChild(stylesheet);

		// const base = document.getElementsByTagName('base')[0];

		// head.insertBefore(stylesheet, base);

	}

}