import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { trigger, transition, state, animate, style, AnimationEvent } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

// services
import { CommonService } from '../../services/common.service';
import { ConfigInternalService } from '../../services/config-internal.service';
import { SessionService } from '../../services/session.service';

//api
import { UserDetailApi } from '../../api/user-detail.api'
import { AccountApi } from '../../api/account.api'
import { AuthApi } from '../../api/auth.api'
import { BillingApi } from '../../api/billing.api'

import { log } from 'util';

import * as $ from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-template',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        position: 'absolute',
        top: '25px'
      })),
      state('closed', style({
        display: 'none'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ]),
      transition('* => closed', [
        animate('0.1s')
      ]),
      transition('* => open', [
        animate('0.1s')
      ]),
      transition('open <=> closed', [
        animate('0.1s')
      ]),
      transition ('* => open', [
        animate ('0.1s',
          style ({ opacity: '*' }),
        ),
      ]),
      transition('* => *', [
        animate('0.1s')
      ]),
    ]),
  ],
  templateUrl: './admin-template.component.html',
  styleUrls: ['./admin-template.component.css']
})

export class AdminTemplateComponent implements OnInit{

  @ViewChild('update_payment_details_popup') update_payment_details_popup:TemplateRef<any>;

  route: string
  hideMenu = true
  isOpen = false;
  show_services: boolean = false
  show_reports: boolean = false
  show_billing: boolean = false
  show_billing_tabs: boolean = false
  show_information_tabs: boolean = false
  show_account_tabs: boolean = false
  dashboard_title: any
  sidebar_left_open: boolean = false
  show_spinner: boolean = false
  sidebar_collapse: boolean = false
  modal_reference:any
  close_result_modal:any

  companies: any = []
  company_id: any = ""

  toggle() {

    this.isOpen = !this.isOpen;
  }

  @Input() logging = false;

  constructor(
    public location: Location, 
    public router: Router, 
    public common_service: CommonService,  
    public internal_config: ConfigInternalService, 
    public session_service: SessionService,
    private user_api: UserDetailApi, 
    private account_api: AccountApi,
    private billing_api: BillingApi,
    private auth_api: AuthApi,
    private modal_service : NgbModal
    ) { }

  onAnimationEvent ( event: AnimationEvent ) {

    if (!this.logging) {
      return;
    }
    // openClose is trigger name in this example
    console.warn(`Animation Trigger: ${event.triggerName}`);

    // phaseName is start or done
    console.warn(`Phase: ${event.phaseName}`);

    // in our example, totalTime is 1000 or 1 second
    console.warn(`Total time: ${event.totalTime}`);

    // in our example, fromState is either open or closed
    console.warn(`From: ${event.fromState}`);

    // in our example, toState either open or closed
    console.warn(`To: ${event.toState}`);

    // the HTML element itself, the button in this case
    console.warn(`Element: ${event.element}`);
  }

  ngOnInit(){
    var url = this.router.url
    var page_title = this.router.url.substring(this.router.url.lastIndexOf("/") + 1)
    this.dashboard_title = page_title.charAt(0).toUpperCase()+page_title.slice(1).replace("-", " ")
    this.getAccountDetail()
    this.company_id = this.session_service.getAccountId()
    // console.log(page_title.charAt(0).toUpperCase()+page_title.slice(1).replace("-", " "))
    // console.log(this.internal_config.getAccount())

  }

  openModal(modal_name) {
    this.modal_reference = this.modal_service.open(modal_name,{centered:true});
    this.modal_reference.result.then((result) => {
      this.close_result_modal = `Closed with: ${result}`;
    }, (reason) => {
      this.close_result_modal = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  closeModal() {
		this.modal_reference.close()
  }
  
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
       return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
       return 'by clicking on a backdrop';
    } else {
       return  `with: ${reason}`;
    }
  }

  getUserDetail() {
    
    this.show_spinner = true
    this.user_api.getUserDetails().subscribe(
      (data:any) => {
        this.internal_config.setUserName(data.name)
        this.internal_config.setRole((data.role).charAt(0).toUpperCase()+ (data.role).slice(1))
        this.internal_config.setPermission(data.permissions)
      }
    )
  }

  getAccountDetail() {

    var account_id = ""
    this.account_api.getAccount().subscribe(

      (data: any) => {

        let month_diff = 0
        if(data[0].billing_contact_updated)
        {
          month_diff = moment().diff(moment(data[0].billing_contact_updated), 'months', true)
        }
        
        if(month_diff >= 6 || data[0].billing_contact_updated == null)
        {
          this.openModal(this.update_payment_details_popup)
        }
        
        this.companies = data
        this.getUserDetail()
        
      }
    )
  }
  
  disablePopUp(){
    this.billing_api.disablePopUp({}).subscribe(
      (data:any) => {
        this.closeModal()
      },
      (error:any) => {
        this.closeModal()
      }
    )
  }

  showServices() {

    this.show_reports = false
    this.show_billing = false
    this.sidebar_collapse= false

    if(this.show_services == true){

      this.show_services = false

    } else{

      this.show_services = true
    }
  }

  showReports() {

    this.show_billing = false
    this.show_services = false 
    this.sidebar_collapse= false   

    if(this.show_reports == true){

      this.show_reports = false

    } else{

      this.show_reports = true
    }
  }


  showBilling() {

    this.show_reports = false
    this.show_services = false
    this.sidebar_collapse= false

    if(this.show_billing == true){

      this.show_billing = false

    } else{

      this.show_billing = true
    }
  }

  showBillingTabs(){

    if(this.show_billing_tabs == true){

      this.show_billing_tabs = false

    } else{

      this.show_billing_tabs = true
    }    
  }

  showInformationTabs(){

    if(this.show_information_tabs == true){

      this.show_information_tabs = false

    } else{

      this.show_information_tabs = true
    }   
  }

  showAccountTabs() {

    if(this.show_account_tabs == true){

      this.show_account_tabs = false

    } else{

      this.show_account_tabs = true
    }  
  }

  showSidebar() {

    if(this.sidebar_left_open == true){

      this.sidebar_left_open = false

    } else{

      this.sidebar_left_open = true
    }

  }

  selectAccount() {

    this.session_service.setAccountId(this.company_id)
    location.reload()

  }

  postLogout(){
    this.auth_api.logout().subscribe(
      (data:any) => {
        this.session_service.logout()
        this.router.navigate(['/auth/login'])
      }
    );
  }

  sidebarCollapse() {

    this.show_reports = false
    this.show_billing = false
    this.show_services = false 
    if(this.sidebar_collapse == true) {

      this.sidebar_collapse= false
    }
    else {
      this.sidebar_collapse = true
    }

  }

  closeOpenTab() {

    this.show_billing = false
    this.show_reports = false
    this.show_services = false
  }

}