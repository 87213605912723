import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class UrlService {

	constructor() { }

	params(obj){

		let str = "";
		for (let key in obj) {
		    if (str != "") {
		        str += "&";
		    }
		    str += key + "=" + encodeURIComponent(obj[key]);
		}

		return str;

	}

}