import { Injectable, EventEmitter } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

@Injectable({
	providedIn: 'root'
})

export class CommonService {

	dashboard_title: any
	service_id: any
	
	constructor(public router: Router, private _location: Location, private route: ActivatedRoute) {}


	getDashboardTitle() {

    	var url = this.router.url
    	var page_title = this.router.url.substring(this.router.url.lastIndexOf("/") + 1)
    	return this.dashboard_title = page_title.charAt(0).toUpperCase()+page_title.slice(1).replace("-", " ")

	}

	
	postLogout() {

		// clear tokens
		this.router.navigate(['/auth/login'])
	}

	goBack() {

		this._location.back()
	}
}