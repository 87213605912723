import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/development.env';

import { UrlService } from '../services/url.service';

@Injectable({
	providedIn: 'root'
})

export class BillingApi {

  base_url:any

  constructor(
    private http: HttpClient,
    private url_service: UrlService
  ) {

    this.base_url = environment.base_url
  }

  /*
  getSurcharges() {
    return this.http.get(this.base_url+`/myalltel/billing/creditcard/surcharges`) 
  }

  createPayment(data) {

    return this.http.post(this.base_url+`/myalltel/billing/creditcard/payment`, data) 
  }

  updateDetails(data) {

    return this.http.post(this.base_url+`/myalltel/billing/directdebit`, data) 
  }
  */

  getTransaction(data) {
    let params = this.url_service.params(data);
    return this.http.get(this.base_url+`/myalltel/billing/transaction?${params}`) 
  }

  getAllTransaction(data) {
    let params = this.url_service.params(data);
    return this.http.get(this.base_url+`/myalltel/billing/alltransaction?${params}`) 
  }

  getSurcharge(data) {

    return this.http.post(this.base_url+'/myalltel/billing/creditcard/surcharge', data) 
  }

  getInvoice(data) {
    let params = this.url_service.params(data);
    return this.http.get(this.base_url+`/myalltel/billing/invoice?${params}`) 
  }

  downloadInvoice(id) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.base_url+"/myalltel/billing/invoice/download/"+id, { headers: headers, responseType: 'blob' })
  }

  getAccount(){
    return this.http.get(this.base_url+'/myalltel/account/details')
  }
 
  getBillingContact(){
    return this.http.get(this.base_url+'/myalltel/billing/contact')
  }

  updateBillingContact(data) {
    return this.http.post(this.base_url+'/myalltel/billing/contact/update', data)
  }

  disablePopUp(data) {
    return this.http.post(this.base_url+'/myalltel/billing/contact/disablepopup', data)
  }

  // Payway 2.0
  getSurcharges() {
    return this.http.get(this.base_url+`/myalltel/billing/payway/get-surcharges`) 
  }

  makePayment(data, idempotency_key) {
    let headers = new HttpHeaders();
    headers = headers.append('idempotency-key', idempotency_key)
    return this.http.post(this.base_url+`/myalltel/billing/payway/make-payment`, data, {headers : headers}) 
  }

  makePaymentUsingStoredCard(data, idempotency_key) {
    let headers = new HttpHeaders();
    headers = headers.append('idempotency-key', idempotency_key)
    return this.http.post(this.base_url+`/myalltel/billing/payway/make-payment-using-stored-card`, data, {headers : headers}) 
  }

  saveDirectDebitDetails(data) {
    return this.http.post(this.base_url+`/myalltel/billing/payway/save-directdebit-details`, data) 
  }

  getDirectDebitDetails() {
    return this.http.get(this.base_url+`/myalltel/billing/payway/get-directdebit-details`) 
  }


}