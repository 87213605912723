<section class="body">
	<!-- start: header -->
	<header class="header">
		<div class="logo-container">
			<a routerLink="/admin/home" class="logo">
				<img src="../assets/images/myAlltel_logo.png" height="30" alt="myAlltel"/>
			</a>
			<div class="d-md-none toggle-sidebar-left" data-toggle-class="sidebar-left-opened" data-target="html" data-fire-event="sidebar-left-opened" (click)="showSidebar()">
				<i class="fas fa-bars" aria-label="Toggle sidebar"></i>
			</div>
		</div>
			<!-- start: search & user box -->
			<div class="header-right">
			
				<span class="separator"></span>
		
				<ul class="notifications">
<!-- 						<li>
						<button class="btn btn-success" type="button"><span class="--people_icon"></span>Manage People</button>
					</li> -->
					<li>
						<select class="custom-select form-control" name="company_id" [(ngModel)]="company_id" (ngModelChange)="selectAccount()">
							<option [value]="company.account_id" *ngFor="let company of companies">{{company.business_name}}</option>    
						</select>
					</li>
				</ul>
		
				<span class="separator"></span>
		
				<div id="userbox" class="userbox profile-info">
					<a class="nav-link" id="dropdownMenuButton" popoverTitle="Settings" aria-haspopup="true" aria-expanded="false" [ngbPopover]="popContent">
						<span class="name">{{internal_config.getUserName()}}&nbsp;&nbsp;<i class="fas fa-angle-down drop-down-arrow"></i></span>
						<span class="role">{{internal_config.getRole()}}</span>
					</a>
					<ng-template #popContent>
							<ul class="nav nav-main">
								<li class="nav-parent">
									<div class="nav-link">
										<a routerLink="/admin/home/user/change-password">
											<i class="fas fa-key"></i>
											<span>Change Password</span>
										</a>
									</div>
								</li>
								<li class="nav-parent">
									<div class="nav-link">
										<a (click)="postLogout()">
											<i class="fas fa-power-off"></i>
											<span role="menuitem" tabindex="-1">Logout</span>
										</a>
									</div>
								</li>
							</ul>
					</ng-template>
				</div> 
			</div>
			
		</header>
		<div [className]="sidebar_left_open == true ? 'inner-wrapper sidebar-left-opened' : 'inner-wrapper'">
			<!-- start: sidebar -->
			<aside id="sidebar-left" [className]="sidebar_collapse == true ? 'sidebar-left sidebar-left-collapsed' : 'sidebar-left'">
			
				<div class="sidebar-header">
					<div class="sidebar-title">
						&nbsp;
					</div>
					<div class="sidebar-toggle d-none d-md-block" data-toggle-class="sidebar-left-collapsed" data-target="html" data-fire-event="sidebar-left-toggle" (click)="sidebarCollapse()">
						<i class="fas fa-bars" aria-label="Toggle sidebar"></i>
					</div>
				</div>
			
				<div class="nano">
					<div class="nano-content">
						<nav id="menu" class="nav-main" role="navigation">
							<ul class="nav nav-main">
								<li class="">
									<a [className]="router.url == '/admin/home' ? 'nav-link active' : 'nav-link'" (click)="closeOpenTab()">
										<a routerLink="/admin/home">
											<i class="fas fa-home"></i>
											<span>Dashboard</span>
										</a>
									</a>
									<a class="nav-link" [className]="router.url == '/admin/home/service' ? 'nav-link active' : 'nav-link'" (click)="closeOpenTab()">
										<a routerLink="/admin/home/service">
											<i class="fas fa-cogs"></i>
											<span>Services</span>
										</a>
									</a>
									<!-- <ul class="nav" *ngIf="show_services">
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/service/live-answering">Live Answering</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/service/fax2email">Fax2Email</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/service/voice2email">Voice2Email</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/service/localconnect">Local Connect</a>
										</li>
									</ul> -->
									<a class="nav-link" (click)="showReports()" routerLink="/admin/home/report/inbound/call-log">
										<i class="fas fa-chart-bar" aria-hidden="true"></i>
										<span>Reports</span>
										<i class="fas fa-angle-down drop-down-arrow"></i>
									</a>
									<ul class="nav" *ngIf="show_reports">
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/report/inbound/call-log">Inbound Call Log</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/report/live-answering/call-log">Contact Centre Call Log</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/report/fax2email">Fax2Email Log</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/report/voice2email">Voice2Email Log</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/report/localconnect">Local Connect</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/report/inbound/summary">Inbound Call Summary</a>
										</li>
									</ul>
									<a class="nav-link" (click)="showBilling()" *ngIf="internal_config.getRole() == 'Admin' || internal_config.getRole() == 'Manager'" routerLink="/admin/home/billing/make-a-payment">
										<i class="fas fa-receipt"></i>
										<span>Billing</span>
										<i class="fas fa-angle-down drop-down-arrow"></i>
									</a>
									<ul class="nav" *ngIf="show_billing">
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/billing/make-a-payment">Make a Payment</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/billing/update-payment-details">Update Payment Details</a>
										</li>
										<li [routerLinkActive]="['active']" *ngIf="internal_config.getRole() == 'Admin' || internal_config.getRole() == 'Manager'">
											<a class="nav-link" routerLink="/admin/home/billing/invoices">Invoices</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/billing/transactions">Transactions</a>
										</li>
										<li [routerLinkActive]="['active']">
											<a class="nav-link" routerLink="/admin/home/billing/billing-contacts">Billing Contacts</a>
										</li>
									</ul>
									<a [className]="router.url == '/admin/home/user' ? 'nav-link active' : 'nav-link'" *ngIf="internal_config.getRole() == 'Admin'" (click)="closeOpenTab()">
										<a routerLink="/admin/home/user">
											<i class="fas fa-user"></i>
											<span>Users</span>
										</a>
									</a>
									<a class="nav-link">
										<a href="https://learn.alltel.com.au/support/solutions"  target="blank">
											<img class="support-hub-icon" src="../assets/images/support hub icon.svg" height="30" alt="myAlltel"/>
											<span>Support Hub</span>
										</a>
									</a>
								</li>
							</ul>
						</nav>
					</div>
			
					<script>
						// Maintain Scroll Position
						if (typeof localStorage !== 'undefined') {
							if (localStorage.getItem('sidebar-left-position') !== null) {
								var initialPosition = localStorage.getItem('sidebar-left-position'),
									sidebarLeft = document.querySelector('#sidebar-left .nano-content');
								
								sidebarLeft.scrollTop = initialPosition;
							}
						}
					</script>
				</div>
			</aside>
			<section role="main" class="">
				<!-- <header class="page-header"> -->
					<!-- <h2>{{dashboard_title}}</h2> -->
				<!-- </header> -->
				<router-outlet></router-outlet>
			</section>
		</div>
		  <!-- Modal -->
		  <!--Update Billing Contact Modal-->
		  <ng-template  #update_payment_details_popup let-modal>
						<div class="modal-body pop-up-body">
							<button type="button" class="close contact-close" (click)="closeModal()" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
							<div class="pop-up-title">Are your billing contact details up to date?</div>
							<div class="pop-up-message">To ensure you receive your monthly invoice as well as other important information please check and confirm your contact details are up to date.</div>
							<a class="btn pop-up-chat" (click)="closeModal()"  routerLink="/admin/home/billing/billing-contacts"><span class="pop-up-button-label">Update contact details</span></a>
							<a class="btn pop-up-chat disable-button" (click)="disablePopUp()"><span class="pop-up-button-label">Don't show this again</span></a>
						</div>
		  </ng-template>
	</section>



