import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/development.env';

@Injectable({
	providedIn: 'root'
})

export class AuthApi {

	base_url = 'https://api.allteltest.com/api'

	constructor(private http: HttpClient) {

    this.base_url = environment.base_url

  }

	login(data){
	  return this.http.post(this.base_url+'/login', data)
	}

  logout(){
    return this.http.post(this.base_url+'/myalltel/logout',null);
  }

  sendPasswordResetEmail(data){
  	return this.http.post(this.base_url+'/myalltel/forgot-password/email', data)
  }

  resetPassword(data) {
    return this.http.post(this.base_url+'/myalltel/forgot-password/reset', data)
  }

  changePassword(data) {
    return this.http.post(this.base_url+'/myalltel/user/password', data)
  }

  linking(token) {
    return this.http.post(this.base_url+'/tokenlogin', token)
  }

}