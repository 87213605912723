import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { FormsModule } from '@angular/forms';


import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
// import { PageNotFoundComponent } from './components/errors/page-not-found.component';

// modules
import { AdminModule } from './modules/admin.module';


// components
import { AdminTemplateComponent } from './components/templates/admin-template.component';
import { AuthTemplateComponent } from './components/templates/auth-template.component';
// import { CustomerTemplateComponent } from './components/templates/customer-template.component';

// services
import { SessionService }  from './services/session.service';
import { TokenInterceptorService }  from './services/token-interceptor.service';
// import { TokenValidationService }  from './services/token-validation.service';

@NgModule({
  declarations: [
    AppComponent,
    AdminTemplateComponent,
    AuthTemplateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    AngularMultiSelectModule,
    EditorModule,
    ChartsModule,
    CookieModule.forRoot(),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([
      {
          path: "",
          redirectTo: "auth/login",
          pathMatch: "full"
      },
      {
          path: 'admin',
          component: AdminTemplateComponent,
          loadChildren: () => import('./modules/admin.module').then(m => m.AdminModule)
      },
      {
          path: 'auth',
          component: AuthTemplateComponent,
          loadChildren: () => import('./modules/auth.module').then(m => m.AuthModule)
      },
      {
          path: "account/login",
          redirectTo: "auth/login",
          pathMatch: "full"
      }
      ], { relativeLinkResolution: 'legacy' }
    )
  ],
  providers: [
    SessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
