import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class ConfigInternalService {

  accounts = []
  account_id: any
  user_name: any
  role:any
  records = []
  show_global_loader: boolean = true
  show_button_loader: boolean = true
  progress:any = -100
  permission = []

  setAccount(account) {

    this.accounts = account
  }

  getAccount() {

    return this.accounts
  }

  setUserName(name) {

    this.user_name = name

  }

  getUserName() {

    return this.user_name
  }

  setRole(role) {

    this.role = role

  }

  getRole() {

    return this.role
  }

  setPermission(permission) {

    this.permission = permission

  }

  getPermission() {

    return this.permission
  }

  setConfigurationData(data) {

    this.records = data
  }

  getConfigurationData() {

    return this.records
  }

  showGlobalLoader(state){
    this.show_global_loader = state;
  }

  showButtonLoader(state){
    this.progress = -100
    if(state){
      this.show_button_loader = true
      let interval = setInterval(() => {
        if(this.progress < -1)
        {
          this.progress = this.progress / 2
        }else{
          clearInterval(interval)
        }
      }, 300 )
    }else{
      this.show_button_loader = false
    }
  }
}