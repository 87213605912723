import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/development.env';

@Injectable({
  providedIn: 'root'
})

export class AccountApi {

  base_url:any

  constructor(private http: HttpClient) {

    this.base_url = environment.base_url

  }

  getAccount(){
    return this.http.get(this.base_url+'/myalltel/accounts')
  }

  getUserDetail(){
    return this.http.get(this.base_url+'/myalltel/user/detail')
  }

  getMostPopularDayOfWeek() {

    return this.http.get("http://uowapp.dianne.wh1.alltel.com.au/api/getpopulardayofweek")
  }

  getMostPopularMonth() {
  
    return this.http.get("http://uowapp.dianne.wh1.alltel.com.au/api/getpopularmonth")
  }

  getAverageCallDuration() {
  
    return this.http.get("http://uowapp.dianne.wh1.alltel.com.au/api/getavgcallduration")
  }

  getLongCallDuration() {

    return this.http.get("http://uowapp.dianne.wh1.alltel.com.au/api/getlongcallduration")
  }
  
  getTotalCallsMonth() {

    return this.http.get("http://uowapp.dianne.wh1.alltel.com.au/api/gettotalcallsmonth")
  }

}